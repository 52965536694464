@import 'variables.scss';

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($theme-colors, default);
  background-color: map-get($theme-colors, background);
  font-size: 0.9rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
  color: map-get($theme-colors, primary);
}

h3 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  color: map-get($theme-colors, secondary);
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  color: map-get($theme-colors, secondary);
}

a {
  color: map-get($theme-colors, primary);
  font-size: 0.8rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: map-get($theme-colors, success);
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: map-get($theme-colors, shades);
  padding-left: 25px;
  padding-right: 25px;
}

button {
  background-color: map-get($theme-colors, primary);
  color: map-get($theme-colors, white-default);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: map-get($theme-colors, success);
}
