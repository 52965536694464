@import '../variables.scss';

.violation-item {
  border: 1px solid map-get($theme-colors, stroke-soft);
  padding: 15px;
  padding-left: 25px;
  margin: 20px 0;
  border-radius: 8px;
  background-color: map-get($theme-colors, shades-darker);

  p {
    margin: 5px 0;
    color: map-get($theme-colors, default);
  }

  strong {
    font-weight: bold;
    color: map-get($theme-colors, primary);
  }

  button {
    background-color: map-get($theme-colors, primary);
    color: map-get($theme-colors, white-default);
    border: none;
    padding: 8px 15px;
    font-size: 0.9rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }

  button:hover {
    background-color: map-get($theme-colors, success);
  }
}
