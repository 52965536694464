@import '../variables.scss';

.onscreen-log-container {
  background-color: map-get($theme-colors, shades-darker);
  padding: 20px;
  padding-left: 25px; /* Extra left padding */
  color: map-get($theme-colors, default);
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  margin-top: 20px;
  height: 200px;
}

.log-messages {
  height: 100%;
  overflow-y: auto;
  background-color: map-get($theme-colors, shades);
  border-radius: 8px;
  border: 1px solid map-get($theme-colors, stroke-soft);
}

.log-message {
  margin: 10px 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  color: map-get($theme-colors, white-default);
}

.log-timestamp {
  color: map-get($theme-colors, secondary);
}
